import React, { Component } from "react";
import Layout from "../components/layout/layout";
import Module from "../components/structure/Module/module";
import Wrapper from "../components/structure/Wrapper/wrapper";
import {Title} from "../components/elements/Title/title";
import {Text} from "../components/elements/Text/text";
import {LinkBtn} from "../components/elements/LinkBtn/linkBtn";

class NotFoundPage extends Component {

    render() {
        return (
            <Layout>
                <Module className="pad-t48 pad-b48">
                    <Wrapper>
                        <Title size35 content="404 error"/>
                        <Text className="marg-b10" size16 content="Page not found" />
                        <LinkBtn to={`/`} content={`Home`} />
                    </Wrapper>
                </Module>
            </Layout>
        );
    }
}

export default NotFoundPage;