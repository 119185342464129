import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import "./linkBtn.scss";

export const LinkBtn = props => {
    const classnames = classNames("e-link", {
        "e-link--strong": props.strong,
        "e-link--middle": props.middle,
        "e-link--small": props.small,
        "e-link--inline-block": props.inlineBlock,
        "e-link--lowercase": props.lowercase,
        "e-link--align-right": props.alignRight,
        "e-link--align-center": props.alignCenter,
    });
    return (
        <Link to={`${props.to}/`} className={props.className ? props.className + ` ` + classnames : classnames} title={props.title}>
            {props.content}
            {props.icon && (<span> > </span>)}
        </Link>
    );
};
